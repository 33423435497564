import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation,
} from "@angular/core";
import { NotificationPolicyService } from "../../services/notification.policy.service";
import { jSith } from "../../util/jquery-replacement";
import { S25Const } from "../../util/s25-const";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25LoadingApi } from "../s25-loading/loading.api";
import { Bind } from "../../decorators/bind.decorator";

@TypeManagerDecorator("s25-ng-notification-policy")
@Component({
    selector: "s25-ng-notification-policy",
    template: `
        <div *ngIf="init" class="notification-policy--wrapper">
            <p *ngIf="!canEdit && !notificationPolicy.approvalType">No notification policy</p>
            <div *ngIf="(!canEdit || editModeOff) && notificationPolicy.approvalType">
                <span>Approval Type: {{ notificationPolicy.approvalType.itemName }}</span>
                <span class="c-displayBlock c-margin-top--half c-margin-bottom--single"
                    >Approval required within
                    <b
                        >{{ notificationPolicy.days }} days, {{ notificationPolicy.hours }} hours, and
                        {{ notificationPolicy.minutes }} minutes
                    </b>
                    of event creation date</span
                >

                <div class="notification-policy--flex" *ngFor="let contact of notificationPolicy.contacts">
                    <span class="notification-policy--contact c-margin-right--half">
                        <s25-item-contact [modelBean]="contact" [includeTypeIcon]="true"></s25-item-contact>
                    </span>
                    <span *ngIf="contact.itemName && contact.notifyType?.itemName" aria-label="Notify Type">{{
                        contact.notifyType.itemName
                    }}</span>
                </div>
            </div>

            <div *ngIf="canEdit && !editModeOff">
                <label
                    ><span class="ngBold">Approval Type</span>
                    <s25-generic-dropdown
                        [items]="this.approvalTypes"
                        [placeholder]="this.bulkItemIds ? this.placeholderText : 'No Notification Policy'"
                        [(chosen)]="notificationPolicy.approvalType"
                        [searchEnabled]="false"
                        (chosenChange)="inlineSave()"
                    ></s25-generic-dropdown>
                </label>

                <ng-container *ngIf="notificationPolicy.approvalType && notificationPolicy.approvalType.itemId != 0">
                    <span class="c-displayBlock c-margin-top--half ngBold">Approval required within</span>
                    <label class="c-margin-top--quarter c-margin-right--single"
                        >Days
                        <input
                            class="numeric-wrapper"
                            type="number"
                            min="0"
                            max="65536"
                            [(ngModel)]="notificationPolicy.days"
                            (change)="inlineSave()"
                        />
                    </label>
                    <label class="c-margin-top--quarter c-margin-right--single"
                        >Hours
                        <input
                            class="numeric-wrapper"
                            type="number"
                            min="0"
                            max="23"
                            [(ngModel)]="notificationPolicy.hours"
                            (change)="inlineSave()"
                        />
                    </label>
                    <label class="c-margin-top--quarter"
                        >Minutes
                        <input
                            class="numeric-wrapper"
                            type="number"
                            min="0"
                            max="59"
                            [(ngModel)]="notificationPolicy.minutes"
                            (change)="inlineSave()"
                        />
                    </label>
                    <span class="c-displayBlock">(of event creation date)</span>

                    <div class="c-margin-top--single">
                        <div
                            *ngFor="let contact of notificationPolicy.contacts; let i = index"
                            class="notification-policy--flex c-margin-bottom--quarter"
                        >
                            <ng-container *ngIf="contact.status != 'del'">
                                <span
                                    (click)="this.removeContact(contact)"
                                    (enter)="this.removeContact(contact)"
                                    tabindex="0"
                                    class="ngCpointer ng-scope c-margin-right--half"
                                    role="button"
                                >
                                    <svg class="c-svgIcon">
                                        <title>Remove {{ contact?.itemName || "new contact" }}</title>
                                        <use
                                            xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#close-x"
                                        ></use>
                                    </svg>
                                </span>
                                <span>
                                    <s25-item-contact
                                        *ngIf="contact.itemName"
                                        [modelBean]="contact"
                                        [includeTypeIcon]="true"
                                    ></s25-item-contact>
                                </span>
                                <span class="ng-binding notification-policy--dropdown" *ngIf="contact.itemName">
                                    <s25-generic-dropdown
                                        [items]="this.notifyTypes"
                                        [(chosen)]="contact.notifyType"
                                        [searchEnabled]="false"
                                        (chosenChange)="setNotifyType($event, i)"
                                    ></s25-generic-dropdown>
                                </span>
                            </ng-container>
                        </div>
                    </div>

                    <div *ngIf="inModal" class="ngRed">{{ this.msg }}</div>

                    <div class="c-margin-top--half">
                        <span class="ngBold">Add Contact</span>
                        <s25-contact-dropdown
                            [(chosen)]="this.newContact"
                            (chosenChange)="addContact($event)"
                            [r25UserOnly]="true"
                        ></s25-contact-dropdown>
                    </div>
                </ng-container>
            </div>

            <s25-loading-inline model="{}"></s25-loading-inline>

            <div *ngIf="hasSaveButton">
                <div *ngIf="isDirty" class="c-margin-top--single">
                    <button class="aw-button aw-button--primary c-margin-right--quarter" (click)="this.save()">
                        Save
                    </button>
                    <!--<button class="aw-button aw-button--danger--outline c-margin-right--quarter" *ngIf="!this.notificationPolicy.isNew" (click)="this.delete()">Delete Policy</button>-->
                    <button class="aw-button aw-button--outline" (click)="this.initFunc()">Cancel</button>
                </div>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25NotificationPolicyComponent implements OnInit, OnChanges {
    @Input() itemId: number;
    @Input() itemTypeId?: number;
    @Input() type?: string;
    @Input() inModal?: string | boolean;
    @Input() canEdit?: string | boolean = true; // EVENT_NOTIFY, SPACE_NOTIFY, RESOURCE_NOTIFY, ORGANIZATION_NOTIFY (N, R, F)
    @Input() editModeOff?: string | boolean = false;
    @Input() bulkItemIds?: number[];
    @Input() hasSaveButton?: boolean = true;
    @Input() eventTypeNotificationPolicy?: any = {};
    @Output() valueChange = new EventEmitter();
    @Input() isBulk?: boolean = false;
    @Input() placeholderText?: string = "Select an Approval Type";
    @Input() data?: PolicyInputData; // pass params for modal instance

    notificationPolicy: any = {};
    mode: "view" | "edit";
    init: boolean = false;
    isDirty: boolean = false;
    msg: string = "";
    newContact = {};

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.initFunc().then(() => {
            this.init = true;
            this.cd.detectChanges();
        });
    }

    approvalTypes = [
        { itemName: "No Notification Policy", itemId: 0 },
        { itemName: "By at least one", itemId: 1 },
        { itemName: "By all", itemId: 2 },
    ];

    notifyTypes = [
        { itemName: "Notify only", itemId: 1 },
        { itemName: "Approval required", itemId: 2 },
    ];

    done() {
        this.init = true;
        S25LoadingApi.destroy(this.elementRef.nativeElement);
        this.cd.detectChanges();
    }

    ngOnInit(): void {
        if (this.data) {
            // if rendered in modal, "unpack" component inputs from data attr
            for (let key in this.data) {
                (this as any)[key] = (this as any).data[key];
            }
        }

        this.hasSaveButton = S25Util.toBool(this.hasSaveButton);
        this.inModal = S25Util.toBool(this.inModal);
        this.canEdit = S25Util.toBool(this.canEdit);
        this.editModeOff = S25Util.toBool(this.editModeOff);
        // this.bulkItemIds = this.bulkItemIds || [this.itemId];

        if (this.type && this.type.indexOf && this.type.indexOf("requirement") > -1) {
            //other_requirement, calendar_requirement -> requirement
            this.type = "requirement";
        }

        this.itemTypeId = this.itemTypeId || S25Const.itemName2Id[this.type];
        this.initFunc().then(() => {
            this.init = true;
            this.cd.detectChanges();
        });
    }

    initFunc() {
        this.isDirty = false;
        this.notificationPolicy.contacts = [];

        if (this.itemId) {
            return NotificationPolicyService.get(this.itemTypeId, this.itemId).then((data) => {
                let approvalTypeId = S25Util.propertyGet(data, "approval_type");
                this.notificationPolicy.isNew = !data;

                if (approvalTypeId) {
                    let approvalTypeModel = S25Util.array.getByProp(this.approvalTypes, "itemId", approvalTypeId);
                    this.notificationPolicy.approvalType = S25Util.deepCopy(approvalTypeModel);
                }

                let duration = S25Util.ISODurationToObj(data.respond_within);
                this.notificationPolicy.days = duration.days;
                this.notificationPolicy.hours = duration.hours;
                this.notificationPolicy.minutes = duration.minutes;

                jSith.forEach(data && data.contact, (key, c) => {
                    let notifyTypeId = S25Util.propertyGet(c, "notify_type");
                    let notifyTypeModel = null;
                    if (notifyTypeId) {
                        notifyTypeModel = S25Util.array.getByProp(this.notifyTypes, "itemId", notifyTypeId);
                    }

                    let find = this.notificationPolicy.contacts.find((f: any) => {
                        return f && f.itemId === c.contact_id;
                    });

                    if (!find) {
                        this.notificationPolicy.contacts.push({
                            itemId: c.contact_id,
                            itemName: c.contact_name,
                            notifyType: S25Util.deepCopy(notifyTypeModel),
                        });
                    }
                });
                this.done();
            });
        } else if (this.eventTypeNotificationPolicy && !this.hasSaveButton) {
            this.notificationPolicy = this.eventTypeNotificationPolicy;
            !this.notificationPolicy.contacts ? (this.notificationPolicy.contacts = []) : "";
            this.done();
            return jSith.resolve(null); // if remove this, ngOnChanges fn got error
        } else {
            return jSith.resolve(null);
        }
    }

    addContact(c: any) {
        this.newContact = {};
        if (S25Util.array.isIn(this.notificationPolicy.contacts, "itemId", c.itemId)) {
            alert(c.itemName + " already has a notification contact for this object");
        } else {
            c.status = "new";
            c.notifyType = S25Util.array.getByProp(this.notifyTypes, "itemId", 1);
            this.notificationPolicy.contacts.push(c);
        }
        this.inlineSave();
    }

    setNotifyType(c: any, i: number) {
        this.notificationPolicy.contacts[i].status = "mod";
        if (this.notificationPolicy.contacts[i].itemId) this.inlineSave();
    }

    selectContact(c: any, index: number) {
        if (S25Util.array.isIn(this.notificationPolicy.contacts, "itemId", c.itemId)) {
            alert(c.itemName + " already has a notification contact for this object");
            this.notificationPolicy.contacts[index] = { status: "new" };
        } else {
            c.status = "new";
            this.notificationPolicy.contacts[index] = c;
            this.inlineSave();
        }
    }

    removeContact(c: any) {
        let contact = S25Util.array.getByProp(this.notificationPolicy.contacts, "itemId", c.itemId);
        if (contact.status === "new") {
            S25Util.array.inplaceRemoveByProp(this.notificationPolicy.contacts, "itemId", c.itemId);
        } else {
            contact.status = "del";
            this.inlineSave();
        }
    }

    err(err: any) {
        S25LoadingApi.destroy(this.elementRef.nativeElement);
        S25Util.showError(err);
    }

    @Bind
    validate() {
        this.msg = "";
        if (
            !this.notificationPolicy.contacts.length ||
            !this.notificationPolicy.contacts.filter((contact: any) => {
                return contact && contact.itemId && contact.status != "del";
            }).length
        ) {
            this.msg = "Please add a contact to this policy";
            !this.inModal && alert(this.msg);
            return false;
        }

        let find = this.notificationPolicy.contacts.find((c: any) => {
            return c && c.status != "del" && !c.notifyType;
        });

        if (find) {
            this.msg = "All contacts require a notify type to be selected";
            !this.inModal && alert(this.msg);
            return false;
        }

        if (!this.notificationPolicy.approvalType) {
            this.msg = "Please select an approval type";
            !this.inModal && alert(this.msg);
            return false;
        }

        return true;
    }

    inlineSave() {
        if (!this.inModal) this.isDirty = true;
        this.valueChange.emit(this.notificationPolicy);
        this.cd.detectChanges();
    }

    delete() {
        S25LoadingApi.init(this.elementRef.nativeElement);
        return NotificationPolicyService.delete(this.itemTypeId, this.itemId).then(
            (resp) => {
                this.initFunc();
                this.done();
                return resp;
            },
            (error) => {
                this.err(error);
            },
        );
    }

    save() {
        if (this.notificationPolicy.approvalType && this.notificationPolicy.approvalType.itemId === 0) {
            this.delete();
        } else if (this.validate()) {
            S25LoadingApi.init(this.elementRef.nativeElement);
            return NotificationPolicyService.set(
                this.itemTypeId,
                this.bulkItemIds || [this.itemId],
                this.notificationPolicy.approvalType.itemId,
                this.notificationPolicy.days || 0,
                this.notificationPolicy.hours || 0,
                this.notificationPolicy.minutes || 0,
                this.notificationPolicy.contacts,
                this.notificationPolicy.isNew,
                !!this.bulkItemIds, //Determine whether to use the bulk service
            ).then(
                (resp) => {
                    this.notificationPolicy.contacts.forEach((contact: any) => {
                        if (contact.status === "del") {
                            S25Util.array.inplaceRemoveByProp(
                                this.notificationPolicy.contacts,
                                "status",
                                contact.itemId,
                            );
                        } else {
                            contact.status = "est";
                        }
                    });

                    this.isDirty = false;
                    this.notificationPolicy.isNew = false;
                    this.done();
                    return resp;
                },
                (error) => {
                    this.err(error);
                },
            );
        } else if (this.inModal) {
            this.cd.detectChanges();
            return Promise.reject({ error: this.msg });
        }
    }

    // for bulk edit, API call to refresh the comp
    refresh() {
        this.init = false;
        this.cd.detectChanges();
        this.ngOnInit();
        this.valueChange.emit(this.notificationPolicy);
    }
}

export type PolicyInputData = {
    itemId: number;
    itemTypeId?: number;
    type?: string;
    inModal?: string | boolean;
    canEdit?: string | boolean;
    editModeOff?: string | boolean;
    bulkItemIds?: number[];
    hasSaveButton?: boolean;
    eventTypeNotificationPolicy?: any;
    isBulk?: boolean;
    placeholderText?: string;
};
