import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25ItemI } from "../../pojo/S25ItemI";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { MultiselectModelI } from "../s25-multiselect/s25.multiselect.component";

@TypeManagerDecorator("s25-ng-notification-policy-contacts")
@Component({
    selector: "s25-ng-notification-policy-contacts",
    template: ` <div
            *ngFor="let contact of this.contacts; let i = index"
            class="notification-policy--flex c-margin-bottom--quarter"
        >
            <span
                (click)="this.remove(contact)"
                (enter)="this.remove(contact)"
                tabindex="0"
                class="ngCpointer ng-scope c-margin-right--half"
                role="button"
            >
                <svg class="c-svgIcon">
                    <title>Remove {{ contact?.itemName || "new contact" }}</title>
                    <use xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#close-x"></use>
                </svg>
            </span>
            <span>
                <s25-item-contact
                    *ngIf="contact.itemName"
                    [modelBean]="contact"
                    [includeTypeIcon]="true"
                ></s25-item-contact>
            </span>
            <span class="ng-binding notification-policy--dropdown" *ngIf="contact.itemName">
                <s25-generic-dropdown
                    [items]="this.notifyTypes"
                    [(chosen)]="contact.notifyType"
                    [searchEnabled]="false"
                    (chosenChange)="setNotifyType($event, i)"
                ></s25-generic-dropdown>
            </span>
        </div>
        <div class="c-margin-top--half">
            <!--            <s25-ng-dropdown-search-criteria [type]="'contacts'" [(chosen)]="this.newContact" (chosenChange)="addContact($event)" [customFilterValue]="customFilterValue"></s25-ng-dropdown-search-criteria>-->
            <s25-ng-multiselect-search-criteria
                [type]="'contacts'"
                [(modelBean)]="this.model"
                [customFilterValue]="customFilterValue"
                honorMatching="true"
            ></s25-ng-multiselect-search-criteria>
        </div>`,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25NotificationPolicyContactsComponent implements OnInit {
    @Input() model: MultiselectModelI = {};
    contacts: S25ItemI[] = []; //this is displayed to the user
    newContact: any;
    notifyTypes = [
        { itemName: "Notify only", itemId: 1 },
        { itemName: "Approval required", itemId: 2 },
    ];
    customFilterValue = "&is_r25user=1&itemName=username";

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    ngOnInit(): void {
        this.model.showResult = false;
        this.model.onDone = () => {
            this.contacts = this.model.selectedItems.map((item) => {
                //Preserve any data set on preselected items
                return S25Util.array.getByProp(this.contacts, "itemId", item.itemId) || item;
            });
            return null;
        };

        this.cd.detectChanges();
    }

    setNotifyType(event: any, i: number) {
        //probably needs to emit to parent?
        this.cd.detectChanges();
    }

    remove(contact: any) {
        S25Util.array.inplaceRemoveByProp(this.model.selectedItems, "itemId", contact.itemId);
        S25Util.array.inplaceRemoveByProp(this.contacts, "itemId", contact.itemId);
        //probably needs to emit to parent?
        this.cd.detectChanges();
    }

    //Deprecated
    addContact(c: any) {
        this.newContact = {};
        if (S25Util.array.isIn(this.model.selectedItems, "itemId", c.itemId)) {
            alert(c.itemName + " already has a notification contact for this object");
        } else {
            c.status = "new";
            c.notifyType = S25Util.array.getByProp(this.notifyTypes, "itemId", 1);
            this.model.origItems.push(c);
        }
        this.cd.detectChanges();
    }
}
