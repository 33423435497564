//@author: devin

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { MultiselectModelI } from "./s25.multiselect.component";
import { Api } from "../../api/api";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";

export class MultiselectResultsApi extends Api {
    static refresh(relativeElem: HTMLElement, uuid: string) {
        return MultiselectResultsApi.callApiFn(
            relativeElem,
            "s25-ng-multiselect-results",
            "refresh",
            null,
            null,
            (comp, i) => {
                return comp && comp.modelBean && comp.modelBean.uuid === uuid;
            },
        );
    }
}

@TypeManagerDecorator("s25-ng-multiselect-results")
@Component({
    selector: "s25-ng-multiselect-results",
    template: `
        <div class="s25-multiselect">
            <div class="s25-multiselect-result">
                <div class="s25-multiselect-popup-position-header">
                    <div
                        *ngIf="
                            this.modelBean.selectedItems.length > 0 &&
                            this.modelBean.showResult &&
                            this.modelBean.showMatching
                        "
                        class="s25-matching-container"
                    >
                        <label class="s25-label s25-label-all"
                            ><input
                                type="radio"
                                name="anyOrAll-{{ id }}"
                                [(ngModel)]="this.modelBean.matching"
                                value="any"
                                (ngModelChange)="this.modelBean.onMatchingChange($event)"
                                class="s25-input"
                            />
                            Any</label
                        >
                        <label class="s25-label s25-label-all"
                            ><input
                                type="radio"
                                name="anyOrAll-{{ id }}"
                                [(ngModel)]="this.modelBean.matching"
                                value="all"
                                (ngModelChange)="this.modelBean.onMatchingChange($event)"
                                class="s25-input"
                            />
                            All</label
                        >
                    </div>
                </div>
                <div
                    *ngIf="this.modelBean.selectedItems.length > 0 && this.modelBean.showResult"
                    class="s25-multiselect-columns-container"
                >
                    <div class="s25-multiselect-columns">
                        <s25-ng-multiselect-item
                            type="cross"
                            [hidden]="item.isSecret"
                            *ngFor="let item of this.modelBean.selectedItems; trackBy: this.trackByFn"
                            [item]="item"
                            [modelBean]="this.modelBean"
                            class="c-tagItem ngInlineBlock"
                        ></s25-ng-multiselect-item>
                    </div>
                </div>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25MultiselectResultsComponent implements OnInit {
    private static count = 0;

    @Input() modelBean: MultiselectModelI;

    id: number;

    trackByFn(idx: any, item: any) {
        return item && item.itemId;
    }

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.id = S25MultiselectResultsComponent.count;
        S25MultiselectResultsComponent.count++;
    }

    refresh = () => {
        try {
            this.cd.detectChanges();
        } catch (error: any) {}
    };

    ngOnInit() {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
        this.modelBean.matching = S25Util.coalesce(this.modelBean.matching, "any");
    }
}
